import * as React from "react"
import Head from "../components/Head";
import Logo from "../images/logo_bw.svg";

const IndexPage = () => {
  return (
    <main className="main d-flex align-items-center justify-content-center bg-light">
      <Head pageUrl="/" pageTitle="Maintenance"/>
      <div className="text-center">
        <Logo class="logo-svg mb-5"/>
        <h2>Undergoing maintenance</h2>
        <h5>We'll be back shortly</h5>
        <br/>
        <h5>For more information, view our <a href="https://status.sparkhire.com" target="_blank" rel="noreferrer">status page</a></h5>
      </div>
    </main>
  )
}

export default IndexPage
